import React, { useEffect, useState, Suspense, lazy } from 'react';
import './MovieList.css';
import MovieCardSkeleton from './MovieCardSkeleton';

const MovieCard = lazy(() => import('./MovieCard'));

const MovieList = ({ movies, onSelectMovie }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulate a loading delay
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="movie-list-1">
        {Array.from({ length: 10 }).map((_, index) => (
          <MovieCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="movie-list">
      <Suspense fallback={<div>Loading...</div>}>
        {movies.map(movie => (
          <MovieCard key={movie.title} movie={movie} onSelectMovie={onSelectMovie} />
        ))}
      </Suspense>
    </div>
  );
};

export default MovieList;
