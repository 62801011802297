import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = ({ onHomeClick }) => {
  const handleHomeClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onHomeClick(); // Call the onHomeClick prop to reset pagination
  };

  return (
    <nav className="navbar">
      {/* Logo with Home link */}
      <Link to="/" className="navbar-brand " onClick={handleHomeClick}>
        <div className="logo">
          <span className="ullu">Series</span><span className="hub">Hub</span>
        </div>
      </Link>
      
      <div className="navbar-links">
        {/* Existing Links */}
        <Link to="/" className="navbar-link" onClick={handleHomeClick}>Home</Link>
        {/* <a href="https://movie.indianseries.site/" className="navbar-link" target="_blank" rel="noopener noreferrer">Movies</a> */}
        <a href="https://t.me/indianhotwebseries_site" className="navbar-link" target="_blank" rel="noopener noreferrer">Telegram</a>
        
        {/* New Link for the Image Gallery */}
        <Link to="https://indianseries.site/display.html" className="navbar-link">Gallery</Link>
      </div>
    </nav>
  );
};

export default Navbar;
