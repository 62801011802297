import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './MoviePlayer.css';
import MovieList from './MovieList';

const getRandomMovies = (movies, count) => {
  const shuffled = movies.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const MoviePlayer = ({ movies }) => {
  const { title } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [clickCount, setClickCount] = useState(0);

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };

  const handleTagClick = (tag) => {
    navigate(`/movies/tag/${encodeURIComponent(tag)}`);
  };

  useEffect(() => {
    setClickCount(0); // Reset click count when the movie title changes
  }, [title]);

  const selectedMovie = movies.find(movie => movie.title === decodeURIComponent(title));

  useEffect(() => {
    if (selectedMovie && videoRef.current) {
      setTimeout(() => {
        videoRef.current.currentTime = 70; // Skip the first 10 seconds
      }, 1000);
    }
  }, [selectedMovie]);

  if (!selectedMovie) return <div>Loading...</div>;

  const recommendedMovies = movies.filter(movie => movie.title !== selectedMovie.title);
  const randomMovies = getRandomMovies(recommendedMovies, 10);

  const isVideo = selectedMovie.iframeSrc && selectedMovie.iframeSrc.startsWith("https://videohb.org");

  const handleDownloadClick = () => {
    if (clickCount < 2) {
      // First or second click: redirect to ad URL
      window.open('https://t.antj.link/246207/3785/0?bo=2753,2754,2755,2756&pyt=multi&po=6456&aff_sub5=SF_006OG000004lmDN', '_blank');
      setClickCount(prevCount => prevCount + 1);
    } else {
      // After two clicks: redirect to the actual download URL
      window.open(selectedMovie.downloadUrl, '_blank');
    }
  };

  return (
    <div className="movie-player">
      <h4>{selectedMovie.Fulltitle || 'Untitled'}</h4>
      {isVideo ? (
        <iframe
          src={selectedMovie.iframeSrc}
          title={selectedMovie.title}
          allowFullScreen
          style={{ width: '100%', height: '300px' }}
        ></iframe>
      ) : (
        <video
          ref={videoRef}
          src={selectedMovie.iframeSrc}
          title={selectedMovie.title}
          autoPlay
          controls
          style={{ width: '100%', height: '300px' }}
        >
          Your browser does not support the video tag.
        </video>
      )}

      <div className="movie-player-buttons">
        {selectedMovie.downloadUrl && (
          <>
            <button className="movie-button-download" onClick={handleDownloadClick}>
              Download
            </button>
            {clickCount < 3 && (
              <span className="click-instructions">
                Click {3 - clickCount} time{clickCount === 2 ? "" : "s"} to get the final download link.
              </span>
            )}
          </>
        )}
      </div>

      <div className="movie-tags">
        {selectedMovie.tags && selectedMovie.tags.map((tag, index) => (
          <button
            key={index}
            className="tag-button"
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </button>
        ))}
      </div>

      <h3 className="recommended-videos-title">Recommended Videos</h3>
      <MovieList movies={randomMovies} onSelectMovie={handleSelectMovie} />
    </div>
  );
};

export default MoviePlayer;
