import React, { useEffect, useState } from 'react';
import './Overlay.css';

const Overlay = ({ monetizationLink }) => {
  const [active, setActive] = useState(true);

  const handleClick = () => {
    window.open(monetizationLink, '_blank');
    setActive(false);
  };

  useEffect(() => {
    if (!active) {
      const timer = setTimeout(() => {
        setActive(true);
      }, 60000); // 10 seconds
      return () => clearTimeout(timer);
    }
  }, [active]);

  if (!active) return null;

  return <div className="overlay" onClick={handleClick}></div>;
};

export default Overlay;
